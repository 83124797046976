import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { disableContextMenu } from 'disable-context-menu';
import * as bootstrap from 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.min.css"

window.bootstrap = bootstrap;

require('@/config/axios');
disableContextMenu();

createApp(App).use(i18n).use(router).mount('#app')
