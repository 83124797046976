<template>
  <router-view/>
</template>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@font-face {
    font-family: Brushwell;
    src: url("~@/assets/font/NVN-Brushwell.ttf") format("opentype");
}
* {
  font-family: 'Open Sans', sans-serif !important;
}
.page-link {
  &:focus {
    box-shadow: none !important;
  }
}
.scrollbar {
    scrollbar-color: rgba(0,0,0,0.4) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255,255,255,1);
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 20px;
    }
}
.v-idle {
  display: none;
}
.name-display {
  p {
    margin-bottom: 0 !important;
  }
}
</style>